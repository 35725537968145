import * as React from "react"
import { Link } from "gatsby"

import {
  FaYoutube,
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa"

import Navbar from "../components/Navbar"
import FooterBar from "../components/Footer"

const socialNavigationClassHeader =
  "w-8 h-8 mr-5 text-blue-600 hover:text-blue-900 lg:w-6 lg:h-6 lg:mr-2"

const socialNavigationClassFooter =
  "w-8 h-8 mr-5 text-blue-50 hover:text-blue-200 lg:w-6 lg:h-6 lg:mr-2"

const navigation = {
  topics: [
    { name: "Transformation", href: "/transformation", current: false },
    { name: "Cloud", href: "/cloud", current: false },
    { name: "Kubernetes", href: "/kubernetes", current: false },
    { name: "Machine Learning", href: "/machine-learning", current: false },
    { name: "DevOps", href: "/devops", current: false },
    {
      name: "About",
      href: "#",
      current: false,
      expandable: true,
      childMenu: [
        { name: "About", href: "/about", current: false },
        { name: "Authors", href: "/authors", current: false },
        { name: "Contact", href: "/contact", current: false },
        { name: "Privacy Policy", href: "/privacy-policy" },
      ],
    },
  ],
  legal: [],
  support: [],
  company: [
    { name: "About", href: "/about" },
    { name: "Authors", href: "/authors" },
    { name: "Contact", href: "/contact" },
    { name: "Privacy Policy", href: "/privacy-policy" },
  ],
  social: [
    // {
    //   name: "Twitter",
    //   href: "#",
    //   icon: props => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    //     </svg>
    //   ),
    // },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/data-beyond-clouds/",
      headerIcon: <FaLinkedin className={socialNavigationClassHeader} />,
      footerIcon: <FaLinkedin className={socialNavigationClassFooter} />,
      current: false,
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UC7Qw2B4AxzoGul7gWZxNqiA",
      headerIcon: <FaYoutube className={socialNavigationClassHeader} />,
      footerIcon: <FaYoutube className={socialNavigationClassFooter} />,
      current: false,
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/Data-Beyond-Clouds-104422501738357",
      headerIcon: <FaFacebook className={socialNavigationClassHeader} />,
      footerIcon: <FaFacebook className={socialNavigationClassFooter} />,
      current: false,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/databeyondclouds/",
      headerIcon: <FaInstagram className={socialNavigationClassHeader} />,
      footerIcon: <FaInstagram className={socialNavigationClassFooter} />,
      current: false,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/Databeyondcloud",
      headerIcon: <FaTwitter className={socialNavigationClassHeader} />,
      footerIcon: <FaTwitter className={socialNavigationClassFooter} />,
      current: false,
    },
  ],
}

// const aboutNavigation = [
//   { name: "About", href: "/about", current: false },
//   { name: "Authors", href: "/authors", current: false },
//   { name: "Contact", href: "/contact", current: false },
// ]

// const navigation = [
//   { name: "Transformation", href: "/transformation", current: false },
//   { name: "Cloud", href: "/cloud", current: false },
//   { name: "Kubernetes", href: "/kubernetes", current: false },
//   { name: "Machine Learning", href: "/machine-learning", current: false },
//   { name: "DevOps", href: "/devops", current: false },
//   {
//     name: "About",
//     href: "#",
//     current: false,
//     expandable: true,
//     childMenu: aboutNavigation,
//   },
// ]

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  console.log("rootPath: " + rootPath)
  console.log("isrootPath: " + isRootPath)

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        {/* <header className="global-header">{header}</header> */}
        <nav>
          <Navbar navigation={navigation} />
        </nav>
        <main>{children}</main>

        {/* <footer> */}

        {/* © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a> */}
        {/* </footer> */}
      </div>
      <FooterBar navigation={navigation} />
    </div>
  )
}

export default Layout
